export default function Blogs() {
    return (
        <div className="col-lg-8">
            <div className="bsingle__post mb-50 wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">
                <div className="bsingle__post-thumb">
                <a href="/blog-noel">  <img src="/assets/img/blog/blog-1/collage 8.jpg" alt="" /></a>
                </div>
                <div className="bsingle__content">
                    <div className="meta-info">
                        <ul><li><a><i className="far fa-clock"></i>il y'a 1 jour</a></li>
                            <li><a><i className="far fa-eye"></i>10 Vues</a></li>

                            <li><a><i className="far fa-comments"></i>0 Commentaires</a></li>
                        </ul>
                    </div>
                    <h2><a href="/blog-noel">Célébrations de Noël : Découvrez les Offres Festives des Exposants du SAB Afr 2024</a></h2>
                    <p>À l'approche des fêtes de fin d'année, la magie de Noël s'installe progressivement, apportant avec elle une
                        ambiance chaleureuse et festive. Les entreprises locales se mobilisent pour offrir des produits uniques et des
                        expériences mémorables, contribuant ainsi à la célébration de cette période tant attendue. Dans cet article,
                        nous mettrons en lumière six exposants du Salon de l'Alimentation et du Bien-Être (SAB Afr 2024), chacun
                        proposant des créations originales qui raviront les consommateurs et enrichiront leurs festivités.</p>
                    <div className="blog__btn">
                        <a href="/blog-noel" className="btn">Voir plus</a>
                    </div>
                </div>
            </div>
            {/* <div className="bsingle__post mb-50 wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">
                <div className="bsingle__post-thumb video-p">
                    <img src="img/blog/inner_b2.jpg" alt="" />
                    <a href="https://www.youtube.com/watch?v=vKSA_idPZkc" className="video-i popup-video"><i className="fas fa-play"></i></a>
                </div>
                <div className="bsingle__content">
                    <div className="meta-info">
                        <ul>
                            <li><a href="#"><i className="far fa-user"></i>by Hubla Dobby</a></li>
                            <li><i className="far fa-comments"></i>35 Comments</li>
                        </ul>
                    </div>
                    <h2><a href="blog-details.html">There are many variations passages of like consectetur lorem ipsum available.</a></h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore.</p>
                    <div className="blog__btn">
                        <a href="#" className="btn">Read More</a>
                    </div>
                </div>
            </div> */}

            {/* <div className="pagination-wrap mb-50">
                <nav>
                    <ul className="pagination">
                        <li className="page-item"><a href="#"><i className="fas fa-angle-double-left"></i></a></li>
                        <li className="page-item active"><a href="#">1</a></li>
                        <li className="page-item"><a href="#">2</a></li>
                        <li className="page-item"><a href="#">3</a></li>
                        <li className="page-item"><a href="#">...</a></li>
                        <li className="page-item"><a href="#">10</a></li>
                        <li className="page-item"><a href="#"><i className="fas fa-angle-double-right"></i></a></li>
                    </ul>
                </nav>
            </div> */}
        </div>
    )
}