import React from 'react'
import Banner from './Banner'
import BlogList from './BlogList'
import Blogs from './Blogs'
import { BlogSidebar } from './blog-sidebar'

function BlogContainer() {
    return (
        <main>
            {/* <!-- banner-section --> */}
            <Banner title="Notre Blog" subtitle="Blog" />

            {/* <BlogList /> */}
            <section className="inner-blog pt-120 pb-140">
                <div className="container">
                    <div className="row">
                      <Blogs />
                       <BlogSidebar />
                    </div>
                </div>
            </section>

           
        </main>
    )
}

export default BlogContainer
