import React from 'react'
import { Helmet } from 'react-helmet'
import Header from '../components/Header'
import GalerieContainer from '../components/GalerieContainer'
import Footer from '../components/Footer'
import '../../node_modules/photoswipe/dist/photoswipe.css'
import { useParams } from 'react-router-dom'

const items_2023 = [
    {
        src: "/assets/img/2023/Photo-1.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-2.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-3.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-4.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-5.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-6.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-7.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-8.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-9.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-10.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-11.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-12.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-13.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-14.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-15.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-16.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-17.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-18.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-19.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-20.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-21.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-22.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-23.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-24.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-25.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-26.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-27.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-28.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-29.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-30.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-31.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-32.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-33.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-34.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-35.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-36.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-37.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-38.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-39.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-40.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-41.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-42.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-43.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-44.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-45.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-46.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-47.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-48.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-49.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-50.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-51.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-52.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-53.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-54.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-55.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-56.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-57.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-58.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-59.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-60.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-61.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-62.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-63.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-64.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-65.jpg",
        width: 500,
        height: 757
    },
    {
        src: "/assets/img/2023/Photo-66.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2023/Photo-67.jpg",
        height: 533,
        width: 800
    }
]

const items_2024 = [
    {
        src: "/assets/img/2024/1.jpg",
        width: 533,
        height: 800
    },
    {
        src: "/assets/img/2024/IMG_7809.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7811.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7812.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7813.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7814.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7816.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7818.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7827.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7828.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7831.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7835.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7877.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7878.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7881.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7882.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7887.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7889.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2024/IMG_7890.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7893.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7894.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7897.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7906.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2024/IMG_7912.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7917.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7919.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7920.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7922.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2024/IMG_7924.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7930.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7931.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2024/IMG_7932.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7936.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2024/IMG_7939.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7947.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7951.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7952.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7955.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7963.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7965.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7969.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7988.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_7989.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2024/IMG_7995.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8008.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2024/IMG_8018.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8028.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8058.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2024/IMG_8065.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8071.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8072.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8074.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8080.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8089.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8094.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8106.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8181.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8184.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8198.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8202.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8203.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8204.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8205.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8206.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8213.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8220.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8223.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8231.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8233.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8243.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8258.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8259.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8261.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8276.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8322.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8343.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8351.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2024/IMG_8356.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2024/IMG_8364.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8387.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8392.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8400.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8423.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8441.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8446.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2024/IMG_8447.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8455.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2024/IMG_8456.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8483.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8501.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8504.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8512.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8525.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8529.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8540.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8545.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2024/IMG_8568.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/IMG_8575.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_7877.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2024/3/IMG_7878.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_7881.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_7887.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_7890.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_7912.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_7917.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_7924.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_7930.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_7947.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_7967.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_7977.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_8582.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_8583.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_8585.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_8586.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_8593.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_8597.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_8605.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_8606.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_8608.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2024/3/IMG_8613.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_8615.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_8626.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_8628.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_8637.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_8652.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_8675.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_8698.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_8727.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_8769.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_8832.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_8969.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_8976.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_9024.jpg",
        height: 533,
        width: 800
    },
    {
        src: "/assets/img/2024/3/IMG_9168.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_9209.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_9210.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_9223.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_9231.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_9232.jpg",
        height: 500,
        width: 757
    },
    {
        src: "/assets/img/2024/3/IMG_9235.jpg",
        height: 500,
        width: 757
    },
]

function Galerie() {
    const {year} = useParams()
    
    return (
        <React.Fragment>
            <Helmet>
                <title>Galerie - SAB</title>
            </Helmet>

            <Header />

            <GalerieContainer 
                images={year === '2023' ? items_2023 : items_2024} 
                year={year} 
            />

            <Footer />
        </React.Fragment>
    )
}

export default Galerie
