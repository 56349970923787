import React from 'react'
import { articles } from '../data/articles'

function BlogList() {
    
    return (
        <section id="blog" class="blog-area p-relative fix pt-100 pb-80">
            <div class="container">
                <div class="section-t team-t paroller" 
                    data-paroller-factor="0.15" 
                    data-paroller-factor-lg="0.15" 
                    data-paroller-factor-md="0.15" 
                    data-paroller-factor-sm="0.15" 
                    data-paroller-type="foreground" 
                    data-paroller-direction="horizontal"
                ><h2>News</h2></div>
                <div class="row justify-content-center">
                    <div class="col-xl-8 col-lg-8">
                        <div class="section-title mb-80">
                            <span class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">feeds</span>
                            <h2 class="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">News Feeds</h2>
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-4 text-right">
                        
                    </div>
                </div>
                
                <div class="row blog-active2 wow fadeInDown animated" data-animation="fadeInDown animated" data-delay=".2s">
                    {articles.map((p, i) => (
                        <div class="col-lg-4 col-md-6" key={i}>
                            <div class="single-post mb-30">
                                <div class="blog-thumb">
                                    <a href={p.link} target='_blank'>
                                        <img src={p.img} alt={"article_" + i} style={{height: 250}} />
                                    </a>
                                </div>
                                <div class="blog-content">
                                    <div class="b-meta mb-20">
                                        <ul>
                                            {/* <li><i class="far fa-comments"></i>35 Comments</li> */}
                                            <li><a target='_blank' href={p.linkProvider}><i class="far fa-user"></i>by {p.provider}</a></li>
                                        </ul>
                                    </div>
                                    <h4>
                                    <a href={p.link}>{p.title}</a>
                                    </h4>
                                    {p.description.map((desc, index) => {return <p key={index}>{desc}</p>})}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}

export default BlogList
