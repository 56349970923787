import React from 'react'
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry'
import Banner from './Banner'
import { Gallery, Item } from 'react-photoswipe-gallery'

function shuffleArray(array) {
    const shuffledArray = array.slice();
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
}

const options = {
    arrowPrev: true,
    arrowNext: true,
    zoom: true,
    close: true,
    loop: true,
    counter: true,
    bgOpacity: 0.6
}

function GalerieContainer({images, year}) {
    
    return (
        <main>
            {/* <!-- banner-section --> */}
            <Banner title="Galerie" subtitle="Galerie" />

            {/* <!-- team-area --> */}
            <section id="contact" className="pt-120 pb-120">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-6 col-lg-8">
                            <div className="section-title text-center mb-80">
                                <span className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">Photos SAB</span>
                                <h2 className="wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">{year}</h2>
                            </div>
                        </div>
                    </div>
                    <Gallery options={options}>
                        <ResponsiveMasonry>
                            <Masonry columnsCount={3} gutter="10px">
                                {shuffleArray(images).map((item, i) => (
                                    <Item
                                        original={item.src}
                                        thumbnail={item.src}
                                        width={item.width}
                                        height={item.height}
                                        key={i}
                                    >
                                        {({ ref, open }) => (
                                            <img 
                                                ref={ref} 
                                                onClick={open} 
                                                src={item.src} 
                                                alt={`Photo-` + i} 
                                                style={{cursor:'pointer'}} 
                                            />
                                        )}
                                    </Item>
                                ))}
                            </Masonry>
                        </ResponsiveMasonry>
                    </Gallery>
                </div>
            </section>
        </main>
    )
}

export default GalerieContainer
