import React, { useEffect, useState } from 'react'

function Header() {
    const [display, setDisplay] = useState(false)
    const screenWidth = window.screen.width

    useEffect(() => {
        const btnMenu = document.querySelector('.responsive')
        const linksLi = document.querySelectorAll('li.has-sub')
        
        btnMenu.addEventListener('click', () => {
            setDisplay(!display)
        })

        linksLi.forEach((li) => {
            li.addEventListener('click', () => {
                const previousActive = document.querySelector('li.active')
                if (previousActive) {
                    previousActive.classList.remove('active')
                }
                li.classList.add('active')
            })
        })
    }, [display])

    return (
        <header id="home" class="header-area">            
            <div id="header-sticky" class="menu-area">
                <div class="container">
                    <div class="second-menu">
                        <div class="row align-items-center">
                            <div class="col-xl-3 col-lg-3">
                                <div class="logo">
                                    <a href="/"><img width={100} height={30} src="/assets/img/logo/logo5.png" alt="logo" /></a>
                                </div>
                            </div>
                            <div class="col-xl-6 col-lg-9">
                                <div class="responsive"><i class="icon dripicons-align-right"></i></div>
                                <div class="main-menu text-right text-xl-center">
                                    <nav id="mobile-menu" style={{display: display || screenWidth > 900 ? 'block' : 'none'}}>
                                        <ul>
                                            <li class="has-sub">
                                                <a href="/home">Accueil</a>
                                            </li>
                                            <li class="has-sub">
                                                <a href="/a-propos">A Propos</a>
                                            </li>
                                            <li class="has-sub">
                                                <a href="/activites">Nos actions+</a>
                                                <ul>
                                                    <li class="has-sub"><a href="/activites">Activités</a></li>
                                                    <li class="has-sub"><a href="/press-book">Articles & Presse</a></li>
                                                </ul>												
                                            </li>
                                            <li class="has-sub">
                                                <a href="/galerie">Galerie+</a>	
                                                <ul>
                                                    <li class="has-sub"><a href="/galerie/2023">2023</a></li>
                                                    <li class="has-sub"><a href="/galerie/2024">2024</a></li>
                                                </ul>											
                                            </li>
                                            <li class="has-sub">
                                                <a href="/d-fit">DFIT</a>
                                            </li>
                                            <li class="has-sub">
                                                <a href="/blog">Blog</a>
                                            </li>
                                            <li class="has-sub">
                                                <a href="/contact">Contact</a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div class="col-xl-3 text-right d-none d-xl-block">
                                <div class="header-btn second-header-btn">
                                    <a href="/contact" class="btn"><i class="far fa-ticket-alt"></i> Devenir sponsor</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}

export default Header
