import { Helmet } from "react-helmet";
import Banner from "../../components/Banner";
import { BlogSidebar } from "../../components/blog-sidebar";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import React from "react";

export default function BlogNoel() {
    return (
        <React.Fragment>
            <Helmet>
                <title>Célébrations de Noël : Découvrez les Offres Festives des Exposants du SAB 
                Afr 2024 - SAB</title>
            </Helmet>

            <Header />

            <main>
                <Banner title="Détails du post" subtitle="Blog" />


                <section className="inner-blog b-details-p pt-120 pb-140 wow fadeInUp animated" data-animation="fadeInUp animated" data-delay=".2s">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-8">
                                <div className="blog-details-wrap">
                                    <div className="meta__info">
                                        <ul><li><a href="#"><i className="far fa-clock"></i>il y'a 1 jour</a></li>
                                            <li><a href="#"><i className="far fa-eye"></i>10 Vues</a></li>

                                            <li><a href="#"><i className="far fa-comments"></i>0 Commentaires</a></li>
                                        </ul>
                                    </div>
                                    <div className="details__content pb-50">
                                        <h2>Célébrations de Noël : Découvrez les Offres Festives des Exposants du SAB
                                            Afr 2024</h2>
                                        <p>À l'approche des fêtes de fin d'année, la magie de Noël s'installe progressivement, apportant avec elle une
                                            ambiance chaleureuse et festive. Les entreprises locales se mobilisent pour offrir des produits uniques et des
                                            expériences mémorables, contribuant ainsi à la célébration de cette période tant attendue. Dans cet article,
                                            nous mettrons en lumière six exposants du Salon de l'Alimentation et du Bien-Être (SAB Afr 2024), chacun
                                            proposant des créations originales qui raviront les consommateurs et enrichiront leurs festivités.</p>
                                        <div className="details__content-img">
                                            <img src="/assets/img/blog/blog-1/collage 8.jpg" alt="" />
                                        </div>
                                        <div class="widget__post-content mb-5"><h4>La Biscuiterie : Un Calendrier de l’Avent Gourmand </h4>
                                        <figure>
                                        <img src="/assets/img/blog/blog-1/IMG_9186.PNG" alt="" />  <p>La Biscuiterie fait sensation avec son calendrier de l’avent. Conçu pour les amateurs de douceurs, ce
                                                calendrier propose une sélection quotidienne de biscuits artisanaux, chacun plus délicieux que le précédent.
                                                En ouvrant chaque petite case, les gourmands découvriront des saveurs variées allant des classiques aux
                                                créations saisonnières. Ce calendrier est non seulement une manière délicieuse de compter les jours jusqu'à
                                                Noël, mais aussi un cadeau parfait pour partager la magie des fêtes avec famille et amie. </p></figure></div>
                                        <div class="widget__post-content mb-5"> <h4>Ma Chandelle : Ambiance Festive avec des Bougies Spéciales  </h4>
                                            <figure>
                                                <img src="/assets/img/blog/blog-1/IMG_9188.PNG" alt="" />  <p>Ma Chandelle présente sa collection exclusive de bougies pour Noël, qui promettent d’apporter chaleur et
                                                    convivialité à chaque foyer. Chaque bougie est soigneusement fabriquée à la main avec des parfums inspirés
                                                    des traditions hivernales, tels que la cannelle, le sapin et le pain d'épices. En plus d’être esthétiques, ces
                                                    bougies créent une atmosphère apaisante, idéale pour les soirées passées au coin du feu ou lors de repas en
                                                    famille. Avec leurs designs élégants, elles constituent également un excellent choix de cadeau pour ceux qui
                                                    apprécient l’artisanat et le bien-être. </p></figure></div>
                                        <div class="widget__post-content mb-5">   <h4>Bold Makeup : Un Marché Festif pour les Passionnés de Beauté</h4>
                                            <figure>
                                                <img src="/assets/img/blog/blog-1/IMG_9189.PNG" alt="" /> <p>Bold Makeup invite tous les passionnés de cosmétiques à son Merry Bold Market, un événement
                                                    incontournable pour découvrir des produits de beauté festifs. Les visiteurs auront l’opportunité d'explorer
                                                    une gamme variée de maquillages inspirés par les couleurs et les thèmes de Noël. Des palettes scintillantes
                                                    aux rouges à lèvres audacieux, chaque produit est conçu pour mettre en valeur la beauté individuelle tout en
                                                    célébrant l'esprit festif. Ce marché est également un lieu idéal pour trouver des cadeaux originaux qui
                                                    raviront vos proches amateurs de maquillage. </p></figure></div>
                                        <div class="widget__post-content mb-5"> <h4>Épices the Chef : Événements Culinaires et Sensibilisation à l’Alimentation Saine </h4>
                                            <figure>
                                                <img src="/assets/img/blog/blog-1/IMG_9300.PNG" alt="" /> <p>Épices the Chef sera présent à deux événements majeurs : la 9ème Édition de la Foire du Coin des Bonnes
                                                    Affaires à la Salle des Fêtes d'Akwa du 04 au 08 décembre 2024, suivie du 16e Édition du Marché de Noël à
                                                    Camtel Bepanda du 17 décembre 2024 au 05 janvier 2025. Ces participations visent non seulement à
                                                    promouvoir leurs épices exquises mais aussi à sensibiliser le public sur les bienfaits d'une alimentation
                                                    saine.  </p></figure></div>

                                        <div class="widget__post-content mb-5">
                                            <h4>Exe Terra : Boîtes de Noël Élégantes et Partenariats Festifs</h4>
                                            <figure>
                                                <img src="/assets/img/blog/blog-1/extra.jpg" alt="" /> <p>Exe Terra se distingue avec ses boîtes de Noël innovantes qui contiennent des boules décoratives remplies
                                                    de liqueur artisanale. Ces créations sont parfaites pour offrir ou pour agrémenter vos propres fêtes. En outre,
                                                    Exe Terra annonce un partenariat excitant avec Wuza pour les célébrations de fin d'année. Ils participeront
                                                    également à l’ASM14 à Douala du 12 au 15 décembre et au Black Diamond Christmas Market du 20 au 24
                                                    décembre. Ces événements permettront aux visiteurs d’explorer leurs produits tout en profitant d’une
                                                    ambiance festive.</p></figure></div>
                                        <div class="widget__post-content mb-5"> <h4>Cadeau Sur Mesure : Offres Personnalisées pour Tous les Goûts</h4>
                                        <figure>
                                        <img src="/assets/img/blog/blog-1/sur mesure.jpg" alt="" /> <p>Cadeau Sur Mesure propose une gamme variée de cadeaux personnalisés avec des réductions spéciales sur
                                                    tous ses packs pendant la période des fêtes. Que ce soit pour un anniversaire ou simplement pour faire
                                                    plaisir, ces cadeaux sur mesure sont conçus pour répondre aux goûts uniques de chaque personne. C'est une
                                                    excellente occasion d'offrir quelque chose d'unique tout en profitant d'offres avantageuses. </p></figure></div>
                                        <div class="widget__post-content mb-5">
                                            <h4>Valexante : Sensibilisation à la Santé des Femmes en Décembre</h4>

                                            <figure>
                                        <img src="/assets/img/partners/valexante.jpg" alt="" /> <p>En décembre, Valexante se mobilise pour sensibiliser le public aux pathologies courantes chez les femmes.
                                                À travers une série de vidéos diffusées sur son site internet et ses réseaux sociaux, l'entreprise vise à
                                                informer sur des sujets de santé cruciaux, notamment les idées reçues liées à la prise de poids.
                                                Valexante propose des conseils pratiques, des témoignages et des informations scientifiques pour aider les
                                                femmes à mieux comprendre leur santé et à prendre en main leur bien-être. Cette initiative souligne
                                                l'importance de l'éducation et de la sensibilisation pour prévenir certaines maladies. Pour suivre cette
                                                campagne essentielle, rendez-vous sur www.valexante.fr et sur leurs comptes Instagram et TikTok. </p></figure>

                                        </div>
                                        <div class="widget__post-content mb-5"> <h4>FALOCAM : Promotions Festives sur les Crèmes Glacées </h4>
                                            <figure>
                                                <img src="/assets/img/blog/blog-1/IMG_9299.PNG" alt="" />
                                                <p>FALOCAM est une entreprise camerounaise spécialisée dans la production de crèmes glacées. Pour célébrer
                                                    la période des fêtes, elle propose plusieurs promotions intéressantes dans les supermarchés. Du 13 décembre
                                                    au 7 janvier, les consommateurs peuvent bénéficier de réductions sur les produits Primo Ice : le format de
                                                    1000 ml passe de 2900 FCFA à 2300 FCFA, et le format de 500 ml est réduit de 1700 FCFA à 1200 FCFA.
                                                    De plus, du 20 décembre au 7 janvier, une réduction sur le format de 200 ml est également offerte, le prix
                                                    étant abaissé de 750 FCFA à 500 FCFA. En parallèle, un jeu concours est organisé sur les réseaux sociaux
                                                    (Facebook, Instagram, TikTok), se clôturant le 20 décembre. Les participants peuvent gagner différentes
                                                    sommes en fonction de l'engagement sur trois posts mettant en avant le produit Primo Ice. </p></figure></div>
                                        <div class="widget__post-content mb-5"> <h4>D-fit : Réduction sur les Abonnements au Restaurant Diététique</h4>
                                            <figure>
                                                <img src="/assets/img/blog/blog-1/IMG_9301.PNG" alt="" /> <p>D-fit est un restaurant diététique qui se consacre à offrir des repas sains et équilibrés pour ceux qui
                                                    souhaitent adopter un mode de vie plus sain. En ce mois de décembre, D-fit propose une promotion
                                                    attractive avec une réduction de 15% sur tous ses abonnements. Cette initiative vise à encourager les clients
                                                    à s'engager dans une alimentation saine tout en profitant d'une variété de plats savoureux et nutritifs. D-fit
                                                    s'efforce de rendre la nutrition accessible et agréable, permettant ainsi aux consommateurs de prendre soin
                                                    de leur santé tout en se faisant plaisir.</p>


                                                <p>Les exposants du SAB Afr 2024 illustrent parfaitement l'esprit festif et créatif qui anime la période de Noël.
                                                    Chacune de ces entreprises apporte sa touche unique, que ce soit par des gourmandises sucrées, des bougies
                                                    parfumées ou des produits cosmétiques audacieux. En soutenant ces artisans locaux, les consommateurs non
                                                    seulement enrichissent leurs célébrations mais participent également au dynamisme économique de leur
                                                    communauté. Cette saison festive est l'occasion idéale pour découvrir ces trésors locaux et faire plaisir à ses
                                                    proches tout en célébrant la magie de Noël</p> </figure>
                                        </div>






                                        {/* <blockquote>
                                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt
                                            with ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation a deef
                                            ullamco laboris nisi ut aliquip ex ea commodo consequat see you again tommorow.
                                            <footer>- Rosalina Pong</footer>
                                        </blockquote> */}

                                        {/* <figure>
                                            <img src="/assets/img/blog/blog-1/collage 8.jpg" alt="" />
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                                                et dolore magna
                                                ali qua. Ut enim ad minim veniam, quis nostrud exercitation ulla mco laboris nisi ut aliquip ex ea
                                                commodo consequat.
                                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                                                pariatur. Excepteur sint
                                                occaecat cupida tat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed
                                                ut perspiciatis
                                                unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam,
                                                eaque ipsa quae ab
                                                illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo.</p>
                                        </figure> */}

                                    </div>
                                    {/* <div className="posts_navigation pt-35 pb-35">
                                        <div className="row align-items-center">
                                            <div className="col-xl-4 col-md-5">
                                                <div className="prev-link">
                                                    <span>Prev Post</span>
                                                    <h4><a href="#">Tips on Minimalist</a></h4>
                                                </div>
                                            </div>
                                            <div className="col-xl-4 col-md-2 text-left text-md-center">
                                                <a href="blog.html" className="blog-filter"><img src="img/icon/c_d01.png" alt="" /></a>
                                            </div>
                                            <div className="col-xl-4 col-md-5">
                                                <div className="next-link text-left text-md-right">
                                                    <span>Prochain article</span>
                                                    <h4><a href="#">Less Is More</a></h4>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}

                                    {/* <div className="avatar__wrap text-center mb-45">
                                        <div className="avatar-img">
                                            <img src="img/blog/comment/avatar.png" alt="" />
                                        </div>
                                        <div className="avatar__info">
                                            <h5>Rosalina William</h5>
                                            <div className="avatar__info-social">
                                                <a href="#"><i className="fab fa-facebook-f"></i></a>
                                                <a href="#"><i className="fab fa-twitter"></i></a>
                                                <a href="#"><i className="fab fa-instagram"></i></a>
                                                <a href="#"><i className="fab fa-behance"></i></a>
                                                <a href="#"><i className="fab fa-linkedin"></i></a>
                                            </div>
                                        </div>
                                        <div className="avatar__wrap-content">
                                            <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore
                                                et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                                                aliquip ex ea commodo consequa aute irure dolor.</p>
                                        </div>
                                    </div> */}
                                    <div className="comment__wrap pb-45 mb-45">
                                        <div className="comment__wrap-title">
                                            <h5>Commentaires (0)</h5>
                                        </div>
                                        {/* <div className="single__comment mb-35">
                                            <div className="comments-avatar">
                                                <img src="img/blog/comment/c_01.png" alt="" />
                                            </div>
                                            <div className="comment-text">
                                                <div className="avatar-name mb-15">
                                                    <h6>Rosalina Kelian</h6>
                                                    <span>19th May 2018</span>
                                                    <a href="#" className="comment-reply"><i className="fas fa-reply"></i>Reply</a>
                                                </div>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                                    ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                                            </div>
                                        </div>
                                        <div className="single__comment children mb-35">
                                            <div className="comments-avatar">
                                                <img src="img/blog/comment/c_02.png" alt="" />
                                            </div>
                                            <div className="comment-text">
                                                <div className="avatar-name mb-15">
                                                    <h6>Tanvir Raihan <i className="fas fa-bookmark"></i></h6>
                                                    <span>19th May 2018</span>
                                                    <a href="#" className="comment-reply"><i className="fas fa-reply"></i>Reply</a>
                                                </div>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                                    ullamco laboris nisi ut
                                                    aliquip commodo.</p>
                                            </div>
                                        </div>
                                        <div className="single__comment">
                                            <div className="comments-avatar">
                                                <img src="img/blog/comment/c_03.png" alt="" />
                                            </div>
                                            <div className="comment-text">
                                                <div className="avatar-name mb-15">
                                                    <h6>Md Subhan</h6>
                                                    <span>21th May 2018</span>
                                                    <a href="#" className="comment-reply"><i className="fas fa-reply"></i>Reply</a>
                                                </div>
                                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                                    ullamco laboris nisi ut
                                                    aliquip ex ea commodo consequat.</p>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className="post-comments-form mb-50">
                                        <div className="comment__wrap-title">
                                            <h5>Faire un Commentaire</h5>
                                        </div>
                                        <div className="comment-box">
                                            <form action="#" className="comment__form">
                                                <div className="comment-field text-area mb-20">
                                                    <i className="fas fa-pencil-alt"></i>
                                                    <textarea name="message" id="message" cols="30" rows="10"
                                                        placeholder="Entrez votre commentaire...."></textarea>
                                                </div>
                                                <div className="comment-field mb-20">
                                                    <i className="far fa-user"></i>
                                                    <input type="text" placeholder="Votre nom...." />
                                                </div>
                                                <div className="comment-field mb-20">
                                                    <i className="fas fa-envelope"></i>
                                                    <input type="email" placeholder="Votre Email...." />
                                                </div>
                                                
                                                <button className="btn">Commenter</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <BlogSidebar />
                        </div>
                    </div>
                </section>
            </main>

            <Footer />
        </React.Fragment>
    )
}