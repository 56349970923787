import React from 'react'
import Banner from './Banner'
import BlogList from './BlogList'

function PressBookContainer() {
    return (
        <main>
            {/* <!-- banner-section --> */}
            <Banner title="Press Book" subtitle="Press Book" />

            {/* <!-- blog-area --> */}
            <BlogList />
        </main>
    )
}

export default PressBookContainer
