export const articles = [
    {
        title: "SAB 2024 : la seconde édition officiellement ouverte",
        provider: "Abelainfo",
        linkProvider: "https://abelainfo.com/",
        img: "https://i0.wp.com/abelainfo.com/wp-content/uploads/2024/11/image_870x_672ce45702dad-2.webp?resize=780%2C470&ssl=1",
        description: [
            "Le 7 novembre 2024, la capitale économique Douala a abrité la cérémonie d'ouverture de la seconde édition du Salon de l’Alimentation et du Bien-être (SAB), dédié à la promotion de l’alimentation équilibrée. L'événement a connu le marrainage de Rosette Ivaha Diboua, épouse du gouverneur de la région du Littoral.",
        ],
        link: "https://abelainfo.com/2024/11/10/sab-2024-la-seconde-edition-officiellement-ouverte/"
    },
    {
        title: "Cameroun – 2ème Salon de l’alimentation et du bien-être : les experts recommandent une alimentation équilibrée contre les maladie",
        provider: "Africa24 TV",
        linkProvider: "https://africa24tv.com/",
        img: "/assets/img/blog/africa24.png",
        description: [
            "Douala , la capitale économique du Cameroun accueille depuis le  7 novembre 2024 , la deuxième édition du salon de l’ alimentation et du bien être placé sous le thème ″ LET’S START THE CHANGE″. Durant ce rendez-vous, les acteurs du secteur de l’agroalimentaire, de la restauration , des médecins et psychologues échangent avec la population de Douala sur l’amélioration de l’alimentation afin de réduire les risques de maladies métaboliques."
        ],
        link: "https://africa24tv.com/cameroun-2eme-salon-de-lalimentation-et-du-bien-etre-les-experts-recommandent-une-alimentation-equilibree-contre-les-maladies"
    },
    {
        title: "Santé : l’alimentation et le bien être au centre de la première édition du SAB",
        provider: "Télé'Asu",
        linkProvider: "https://www.facebook.com/TeleAsuOfficiel",
        img: "https://teleasu.tv/wp-content/uploads/2023/11/image-SAB-820x394.jpg",
        description: [
            "Une conférence de presse s'est tenue ce mardi 21 novembre 2023 en prélude au Salon de l'Alimentation et du Bien-être qui se tiendra à Douala du 7 au 9 décembre 2023."
        ],
        link: "https://teleasu.tv/sante-lalimentation-et-le-bien-etre-au-centre-de-la-premiere-edition-du-sab/?fbclid=IwY2xjawF6EO1leHRuA2FlbQIxMAABHSWYpfskyNyQU4OsFOtPzA6cZakA8RjeT-un0tR2ZWTTaFZG1rQKztDjyw_aem_R7e-tb0Jjk3zwCDem-IEpA"
    },
    {
        title: "Un festin pour tous les sens : Le salon de l'alimentation et du bien-être au Cameroun",
        provider: "WorldCom",
        linkProvider: "https://worldcom-mediagroup.com/",
        img: "https://worldcom-mediagroup.com/wp-content/uploads/2023/11/sant.jpeg",
        description: [
            "Sous le thème : ‘Une alimentation saine et équilibrée pour tous’",
            "Les voix les plus autorisées en matière d’alimentation et du bien-être se retrouveront du 07 au 09 décembre 2023 à Douala, pour vulgariser auprès du public l’alimentation simple et quantitative."
        ],
        link: "https://worldcom-mediagroup.com/sante-un-festin-pour-tous-les-sens-le-salon-de-lalimentation-et-du-bien-etre-au-cameroun/"
    },
    {
        title: "La Santé Alimentaire, un Véritable enjeu pour la Croissance de l'Homme",
        provider: "CEN Télévision",
        linkProvider: "https://www.youtube.com/c/CENT%C3%A9l%C3%A9vision",
        img: "/assets/img/blog/cen.jpg",
        description: [],
        link: "https://www.youtube.com/watch?v=M-jkuE3vVto"
    },
    {
        title: "Le salon de l’alimentation et du bien être se prépare",
        provider: "Télé'Asu",
        linkProvider: "https://www.youtube.com/@TeleasuOfficiel",
        img: "/assets/img/themes/tvasu.png",
        description: [
            "Le première édition du salon de l’alimentation et du bien être, le SAB se tiendra à #Douala du 7 au 9 décembre 2023.",
            "Comment mener une vie saine? Quels aliments choisir pour son alimentation et quelle portion consommer? Les articulations expliquées 👇"
        ],
        link: "https://www.youtube.com/watch?v=hq1NTMkXg5Q"
    },
    {
        title: "Health: Food and well-being fair, announced for December 7,8&9,2023 in Douala.",
        provider: "WorldCom",
        linkProvider: "https://worldcom-mediagroup.com/",
        img: "https://worldcom-mediagroup.com/wp-content/uploads/2023/11/sant.jpeg",
        description: [
            "It will be the first edition of the food and well-being fair in the city of Douala with theme 'A healthy and balanced diet for everyone' which seeks to promote quality life style through free education and sensitization of the common Cameroonians."
        ],
        link: "https://worldcom-mediagroup.com/health-food-and-well-being-fair-announced-for-december-7892023-in-douala/"
    },
    {
        title: "🍌[Santé]. Cameroun",
        provider: "Haurizon News",
        linkProvider: "https://haurizonnews.com/",
        img: "https://haurizonnews.com/uploads/images/202311/image_870x_655cf84540929.webp",
        description: [
            "SAB 2023 : la santé dans l’assiette 🍱 pour un bien-être 🐟",
            "Pour sa première édition, le Salon de l’alimentation 🥗 et du bien-être 🏋🏽se veut être une plateforme d’échanges guidée par les voix les plus autorisées du secteur. Pas seulement🍍."
        ],
        link: "https://haur.be/TmVln"
    },
    {
        title: "SANTÉ : LE SALON DE L'ALIMENTATION ET DU BIEN-ÊTRE (SAB) SE PRÉPARE AU CAMEROUN",
        provider: "Afric-eco",
        linkProvider: "https://afric-eco.com/",
        img: "https://i0.wp.com/afric-eco.com/wp-content/uploads/2023/11/IMG-20231122-WA0130.jpg?resize=800%2C445&ssl=1",
        description: [
            "Une 1ère Édition Qui Se Tiendra À Douala Du 07 Au 09 Décembre 2023.",
            "L'annonce de cet évènement d'envergure a été faite aucour d'une conférence de presse donnée ce Mardi 21 Novembre 2023 à l'hôtel Starland Bonapriso dans le Premier Arrondissement de…"
        ],
        link: "https://afric-eco.com/?p=1706"
    },
    {
        title: "Santé : l’alimentation et le bien être au centre de la première édition du SAB",
        provider: "Télé'Asu",
        linkProvider: "https://teleasu.tv/",
        img: "https://teleasu.tv/wp-content/uploads/2023/11/image-SAB-820x394.jpg",
        description: [
            "Une conférence de presse s’est tenue au Star Land Hôtel ce mardi 21 novembre 2023 en prélude au Salon de l’Alimentation et du Bien-être."
        ],
        link: "https://teleasu.tv/sante-lalimentation-et-le-bien-etre-au-centre-de-la-premiere-edition-du-sab/"
    },
    /*{
        title: "«Bien-être dans l’assiette» : la Camerounaise Ingrid Ngouné en fait son cheval de bataille",
        provider: "LaPlumeDelAigle",
        linkProvider: "https://ndengue.com/",
        img: "https://ndengue.com/wp-content/uploads/2023/11/Salon-du-bien-etre--1024x676.jpg",
        description: [],
        link: "https://ndengue.com/bien-etre-dans-lassiette-la-camerounaise-ingrid-ngoune-en-fait-son-cheval-de-bataille/"
    },
    {
        title: "Santé et bien-être : le SAB 2e édition arrive...",
        provider: "Volum3Media",
        linkProvider: "https://volum3media.com/",
        img: "https://volum3media.com/upload/news/main/6719ebc724a1c0.27281958.jpg?=1730106089",
        description: [],
        link: "https://volum3media.com/news/le-sab-2e-edition-arrive-80"
    },*/
    {
        title: "La deuxième Édition du Salon de l’Alimentation et du Bien-Être s’ouvre le 7 novembre",
        provider: "Vitrine du Cameroun",
        linkProvider: "https://vitrineducameroun.com/",
        img: "https://vitrineducameroun.com/wp-content/uploads/2024/10/Salon-de-lalimentation.jpeg.webp",
        description: [],
        link: "https://vitrineducameroun.com/2024/10/25/la-deuxieme-edition-du-salon-de-lalimentation-et-du-bien-etre-souvre-le-7-novembre/"
    }
]